import React, {useEffect, useState} from "react";
import {Button} from "../Button";
import {ResultOutput} from "../TreeOutput/ApiResultOutput";

export const BulkApiJobStatus = (props) => {
    const sp = new URLSearchParams(window.location.search)
    const initialJobId = sp.get('jobId');
    const [jobId, setJobId] = useState(initialJobId || "");
    const [intervalId, setIntervalId] = useState(-1);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [jobStatusResult, setJobStatusResult] = useState(null);
    const {orgId} = props;

    useEffect(() => {
        if (initialJobId != null) {
            startInterval();
        }
        return(() => {
            if (isIntervalActive()) {
                clearInterval(intervalId);
            }
        });
    }, []);

    const startInterval = () => {
        const started = true;
        const id = setInterval(async () => {
            const requestOptions = {
                method: 'GET', headers: {'Content-Type': 'application/json'},
            };
            setIsRefreshing(true);
            const result = await fetch(`/object_metadata/job_status/${orgId}/${jobId}`, requestOptions);
            const jsonResult = await result.json();
            if (started || isIntervalActive()) {
                setJobStatusResult(jsonResult);
            }
            setIsRefreshing(false);
        }, 5000);
        setIntervalId(id);
    }

    const sendRequest = (e) => {
        e.preventDefault();
        startInterval();
    }

    const onCancelClick = (e) => {
        e.preventDefault();
        if (isIntervalActive()) {
            clearInterval(intervalId);
            setIntervalId(-1);
        }
    }

    const isIntervalActive = () => {
        return intervalId !== -1;
    }

    return (<React.Fragment>
        <div className={"row"}>
            <div className={"col-xs-12"}>
                <form>
                    <div className={"form-group"}>
                        <label htmlFor="jobId">JobId</label>
                        <input
                            type="text"
                            className="form-control"
                            id="jobId"
                            placeholder="Job Id"
                            value={jobId}
                            onChange={(e) => {
                                setJobId(e.target.value);
                            }}/>
                    </div>
                    <Button
                        title={isIntervalActive() ? "Polling results" : "Submit"}
                        className={"pull-right"}
                        onClick={sendRequest}
                        disabled={isIntervalActive()}/>
                </form>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-xs-12"}>
                <h4 style={{borderBottom: "1px solid #eee"}}>Output</h4>
                {isIntervalActive() ? <span>Checking job status every 5s, click <a
                    onClick={onCancelClick} style={{cursor: 'pointer'}}>here</a> to cancel</span> : null}
                {isRefreshing ? <span> - refreshing....</span> : null}
                {jobStatusResult != null && jobStatusResult['jobInfoResult'] != null ?
                    <JobStatusLayout result={jobStatusResult} orgId={orgId} jobId={jobId}/> : null}
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-xs-12"}>
                {isIntervalActive() && jobStatusResult === null ?
                    <span>Loading results...</span> : null}
            </div>
        </div>
    </React.Fragment>);
}

const JobStatusLayout = (props) => {
    const {result, orgId, jobId} = props;
    const jobInfo = result['jobInfoResult']['jobInfo'];
    const jobBatches = result['jobBatchesResult']['batchInfoList'];

    console.log(result);

    if (jobInfo != null) {
        return (<React.Fragment>
            <JobStatusHeader jobInfo={jobInfo}/>
            <JobStatusBatches batches={jobBatches} orgId={orgId} jobId={jobId}/>
        </React.Fragment>)
    } else {
        return <ResultOutput result={result}/>;
    }
}

const JobStatusHeader = (props) => {
    const {jobInfo} = props;
    const keys = Object.keys(jobInfo);
    const chunkSize = 5;
    const keyChunks = splitIntoChunks(keys, chunkSize);

    return (<React.Fragment>
        <h4>Job Info</h4>
        <table className={"table table-bordered"}>
            <tbody>
            {keyChunks.map((kc, idx) => {
                // Always output chunkSize number of items so that table does not appear broken
                const rowArray = new Array(chunkSize).fill(0);
                return <tr key={idx}>
                    {rowArray.map((r, idx2) => {
                        const k = kc[idx2];
                        return (<React.Fragment key={idx2}>
                            <th scope={"row"}>{k}</th>
                            <td>{jobInfo[k]}</td>
                        </React.Fragment>);
                    })}
                </tr>
            })}
            </tbody>
        </table>
    </React.Fragment>);
}

const JobStatusBatches = (props) => {
    console.log(props);
    const {batches, orgId, jobId} = props;
    let batchInfo = batches['batchInfo'];
    console.log(batchInfo);
    const attrs = Object.keys(batchInfo[0]).filter((attr) => {
        return !['totalProcessingTime', 'apiActiveProcessingTime', 'apexProcessingTime'].includes(attr);
    });
    return (<React.Fragment>
        <h4>Batches</h4>
        <table className={"table table-bordered"}>
            <thead>
            <tr>
                {attrs.map((h, idx) => {
                    return <th key={idx}>{h}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {batchInfo.map((batch, idx) => {
                return <tr key={idx}>
                    {attrs.map((attr, idx2) => {
                        return <td key={idx2}>
                            <BatchAttr attr={attr} attrData={batch[attr]} orgId={orgId} jobId={jobId}
                                       batchId={batch['id']}/>
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>

    </React.Fragment>);
}

const BatchAttr = ({attr, attrData, orgId, jobId, batchId}) => {
    const baseUrl = `/object_metadata/job_status/${orgId}/${jobId}/batch`;
    if (attr === 'id') {
        const url = `${baseUrl}/${batchId}/request`;
        return <a href={url} download>{attrData}</a>;
    } else if (attr === 'state') {
        if (['Completed', 'Failed'].includes(attrData)) {
            const url = `${baseUrl}/${batchId}/result`;
            return <a href={url} download>{attrData}</a>;
        } else {
            return attrData;
        }
    } else if (attr === 'resultIds') {
        if (!attrData) {
            return null;
        }
        return (<React.Fragment>
            {attrData.map((resultId, idx) => {
                const resultUrl = `${baseUrl}/${batchId}/result/${resultId}`;
                return <div key={idx}><a href={resultUrl} download>{resultId}</a><br/></div>
            })}
        </React.Fragment>);
    } else {
        return attrData;
    }
}

const splitIntoChunks = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}
