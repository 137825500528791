import React, {useState} from "react";

const marginIncrement = 10;

export const isObject = (value) => {
    return typeof value === 'object' && value != null;
}

const TreeFolderItem = ({name, itemsOrValue, marginLeft = 0}) => {
    const [isOpened, setIsOpened] = useState(false);

    const icon = isOpened ? 'icon-folder-open' : 'icon-folder-close';

    let output = null;

    if (isObject(itemsOrValue)) {
        output = <React.Fragment>
            <p style={{cursor: 'pointer'}}
               onClick={() => {
                   setIsOpened(!isOpened)
               }}><i className={icon}/>&nbsp;{name}</p>
            {isOpened ? <TreeOutput items={itemsOrValue} marginLeft={marginLeft + marginIncrement}/> : null}
        </React.Fragment>;
    } else {
        output = <p><i className={'icon-flag'}/>&nbsp;<strong>{name}:</strong>&nbsp;{itemsOrValue}</p>;
    }
    return <div style={{marginLeft: `${marginLeft}px`}}>
        {output}
    </div>
}

export const TreeOutput = ({items, marginLeft = 0}) => {
    const elements = []
    Object.keys(items).forEach((k) => {
        elements.push(<TreeFolderItem key={k} name={k} itemsOrValue={items[k]} marginLeft={marginLeft}/>)
    })
    return <React.Fragment>
        {elements}
    </React.Fragment>;
}