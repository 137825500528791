import React from 'react';

export const SimpleInput = (props) => {
    const {value, onChange} = props;

    const triggerChange = (e) => {
        onChange(e.target.value);
    };

    return <input className={"form-control"}
                  style={{width: '100%'}}
                  value={value || ""}
                  onChange={triggerChange}/>;
};