import React from 'react';

export const SimpleSelect = (props) => {
    const {value, options, onChange, emptyLabel, allowEmpty = false, disabled = false} = props;

    const opts = [
        {label: emptyLabel, value: '', disabled: !allowEmpty},
        ...options
    ];

    const triggerChange = (e) => {
        onChange(e.target.value);
    }

    return <select className="form-control" value={value} onChange={triggerChange} placeholder={""} disabled={disabled}>
        {opts.map(({label, value, disabled = false}, idx) => {
            return <option key={idx} value={value} disabled={disabled}>{label}</option>
        })}
    </select>;
};