import React from "react"
import {useState} from "react";
import {Button} from "./Button";

const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export const FieldConfigurator = (props) => {

    const {columns, modelName, endpoint, persistedVisibleColumns, objectId} = props;

    const realModelName = modelName.replace('/', '~');

    let formAction = `/admin/${realModelName}/${endpoint}`;
    if (objectId) {
        formAction = `/admin/${realModelName}/${objectId}/${endpoint}`;
    }
    const formMethod = 'post';

    const csrfParam = $('meta[name=csrf-param]').attr('content');
    const csrfToken = $('meta[name=csrf-token]').attr('content');

    let initialAvailableColumns = columns.filter(c => {
        return !persistedVisibleColumns.includes(c);
    });

    const [availableColumns, setAvailableColumns] = useState(initialAvailableColumns.sort());
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(persistedVisibleColumns);
    let initialSelectedVisible = '';
    if (persistedVisibleColumns.length > 0) {
        initialSelectedVisible = persistedVisibleColumns[0];
    }
    const [selectedVisibleColumn, setSelectedVisibleColumn] = useState(initialSelectedVisible);

    const updateSelection = (e) => {
        const selected = Array.from(e.target.selectedOptions).map(s => s.value);
        setSelectedColumns(selected);
    };

    const makeColumnsVisible = (e) => {
        e.preventDefault();
        const newVisibleColumns = [...visibleColumns, ...selectedColumns];
        setVisibleColumns(newVisibleColumns);
        const newColumns = [...availableColumns].filter((c) => {
            return !newVisibleColumns.includes(c);
        });
        setAvailableColumns(newColumns.sort());
        setSelectedColumns([]);
        setSelectedVisibleColumn(selectedColumns[selectedColumns.length - 1]);
    };

    const selectVisibleColumn = (e) => {
        const column = e.target.value;
        setSelectedVisibleColumn(column);
    };

    const removeVisibleColumn = (e) => {
        e.preventDefault();
        if (selectedVisibleColumn !== '') {
            const newColumns = [...availableColumns, selectedVisibleColumn].sort();
            setAvailableColumns(newColumns);

            const newVisibleColumns = [...visibleColumns].filter(c => c !== selectedVisibleColumn);
            setVisibleColumns(newVisibleColumns);

            if (newVisibleColumns.length > 0) {
                setSelectedVisibleColumn(newVisibleColumns[0])
            } else {
                setSelectedVisibleColumn('');
            }
        }
    };

    const moveUp = (e) => {
        e.preventDefault();
        if (selectedVisibleColumn !== '') {
            const index = visibleColumns.indexOf(selectedVisibleColumn);
            if (index > 0) {
                const newVisibleColumns = arrayMove([...visibleColumns], index, index - 1);
                setVisibleColumns(newVisibleColumns);
            }
        }
    };

    const moveDown = (e) => {
        e.preventDefault();
        if (selectedVisibleColumn !== '') {
            const index = visibleColumns.indexOf(selectedVisibleColumn);
            if (index < visibleColumns.length - 1) {
                const newVisibleColumns = arrayMove([...visibleColumns], index, index + 1);
                setVisibleColumns(newVisibleColumns);
            }
        }
    };

    const viewFragment = objectId ? 'details' : 'index';
    const viewInfo = `You are configuring columns for the ${viewFragment} page`;

    return (
        <React.Fragment>
            <div className={"row"}>
                <div className={"col-xs-12"}>
                    <div className="alert alert-info" role="alert">{viewInfo}</div>
                </div>
                <div className={"col-xs-6"}>
                    <form>
                        <div className={"form-group"}>
                            <label htmlFor={"column_options"}>Available columns</label>
                            <select multiple={true}
                                    name={"column_option"}
                                    className={"form-control"}
                                    value={selectedColumns}
                                    onChange={updateSelection}>
                                {availableColumns.map(((c, idx) => <option key={idx} value={c}>{c}</option>))}
                            </select>
                        </div>
                        <div className={"form-group"}>
                            <Button
                                title={"Move to visible"}
                                disabled={selectedColumns.length === 0}
                                onClick={makeColumnsVisible}
                            />
                        </div>
                    </form>
                </div>
                <div className={"col-xs-6"}>
                    <form action={formAction} method={formMethod}>
                        <input type={"hidden"} name={csrfParam} value={csrfToken}/>
                        <div className={"form-group"} style={{display: 'none'}}>
                            <select
                                multiple={true}
                                readOnly={true}
                                name={"visible_columns_list[]"}
                                className={"form-control"}
                                value={visibleColumns}>
                                {visibleColumns.map(((c, idx) => <option key={idx} value={c}>{c}</option>))}
                            </select>
                        </div>
                        <div className={"form-group"}>
                            <label htmlFor={"visible_columns"}>Visible columns</label>
                            <select
                                size={4}
                                name={"visible_columns"}
                                className={"form-control"}
                                value={selectedVisibleColumn}
                                onChange={selectVisibleColumn}>
                                {visibleColumns.map(((c, idx) => <option key={idx} value={c}>{c}</option>))}
                            </select>
                        </div>
                        <div className={"form-group"}>
                            <div className="btn-toolbar" role="toolbar">
                                <div className="btn-group" role="group">
                                    <Button
                                        title={"Remove"}
                                        disabled={selectedVisibleColumn === ''}
                                        onClick={removeVisibleColumn}
                                    />
                                </div>
                                <div className="btn-group" role="group">
                                    <Button
                                        title={"Move up"}
                                        disabled={visibleColumns.length === 0}
                                        onClick={moveUp}
                                    />
                                    <Button
                                        title={"Move down"}
                                        disabled={visibleColumns.length === 0}
                                        onClick={moveDown}
                                    />
                                </div>
                                <div className="btn-group" role="group">
                                    <Button
                                        title={"Save"}
                                        type={"submit"}
                                        className={"btn-primary"}
                                        icon={"icon-white icon-ok"}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};
