import React from "react";

export const NavPill = (props) => {
    const {isActive, title, onPillClick, children} = props;

    return (
        <React.Fragment>
            <li className={isActive === true ? 'active' : null} style={{cursor: 'pointer'}}>
                <a onClick={onPillClick}>{title}</a>
            </li>
        </React.Fragment>
    )
}

NavPill.displayName = 'NavPill';