import React from "react";

export const Input = (props) => {
    const {
        name,
        label,
        onChange,
        value,
        required = false,
        placeholder = "",
        labelWidth = 2,
        inputWidth = 10,
        type = "text"
    } = props;

    const onInputChange = (e) => {
        onChange(name, e.target.value);
    }

    return <div className={"form-group control-group"}>
        <label className={`col-sm-${labelWidth} control-label`} htmlFor={name}>{label}</label>
        <div className={`col-sm-${inputWidth} controls`}>
            <input type={type} required={required} name={name} className={"form-control"} size={50} value={value || ""}
                   onChange={onInputChange} placeholder={placeholder}/>
            <span className="help-block">{required ? 'Required' : 'Optional'}</span>
        </div>
    </div>;
};