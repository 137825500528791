import React from "react";

export const PrettyArgs = ({job_args}) => {
    const keys = Object.keys(job_args);
    return keys.map(k => {
        let name = k;
        if (k.startsWith("admin_")) {
            name = k.replace("admin_", "").toUpperCase();
        }
        return <p key={k}><strong>{name}:</strong> {job_args[k].toString()}</p>;
    });
}