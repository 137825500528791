import React, {useState} from "react";
import {QueryInput} from "./QueryInput";
import {QueryOutput} from "./QueryOutput";
import {ObjectExplorer} from "./ObjectExplorer";
import {getCsrfParams} from "../CsrfInput";

export const SoqlQuery = ({orgId, archiveObjectNames}) => {
    const [recordIdx, setRecordIdx] = useState(1);
    const [queryResult, setQueryResult] = useState(null);
    const [isSending, setIsSending] = useState(false);

    const sendToBackend = async (requestBody) => {
        setIsSending(true);
        const {csrfParam, csrfToken} = getCsrfParams();
        requestBody[csrfParam] = csrfToken;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(requestBody)
        };
        let jsonResult = null;
        let queryError = null;
        try {
            const result = await fetch('/object_metadata/soql_query', requestOptions);
            jsonResult = await result.json();
        } catch (e) {
            console.log(e.message);
            queryError = e.message;
        }

        if (jsonResult != null) {
            if (requestBody["use_bulk"] === true) {
                const jobId = jsonResult["id"];
                if (jobId) {
                    const loc = window.location
                    const base = loc.origin + loc.pathname;
                    window.location.href = base + `?jobId=${jobId}`;
                }
            } else {
                setQueryResult(jsonResult);
            }
        } else {
            alert(queryError);
        }


        setIsSending(false);
    }

    const submitQuery = async ({encodedQuery, useBulk}) => {
        setRecordIdx(1);
        const requestBody = {
            org_id: orgId,
            soql_query: encodedQuery,
            use_bulk: useBulk
        }
        await sendToBackend(requestBody);
    }

    const fetchNextPage = (url) => {
        return async (e) => {
            e.preventDefault();
            if (!!queryResult.records) {
                setRecordIdx((prevState) => {
                    return prevState + queryResult.records.length;
                })
            }
            const requestBody = {
                org_id: orgId,
                next_page: url
            }
            await sendToBackend(requestBody);
        }
    }


    return (<div className="row">
        <div className="col-sm-8">
            <div className="row">
                <div className={"col-sm-12"}>
                    <QueryInput
                        onSubmit={submitQuery}
                        orgId={orgId}
                        isSending={isSending}/>
                </div>
            </div>
            <div className="row">
                <div className={"col-sm-12"} style={{overflow: 'auto'}}>
                    <QueryOutput
                        result={queryResult}
                        onFetchNextPage={fetchNextPage}
                        isSending={isSending}
                        recordIdx={recordIdx}/>
                </div>
            </div>
        </div>
        <div className="col-sm-4">
            <ObjectExplorer
                objectNames={archiveObjectNames}
                orgId={orgId}/>
        </div>
    </div>);
}