import React from 'react';

export const Checkbox = (props) => {
    const {name, label, value, onChange} = props;
    return <div className="form-group control-group boolean_type">
        <label className="col-sm-2 control-label" htmlFor={name}>{label}</label>
        <div className="col-sm-10 controls">
            <div className="checkbox">
                <label style={{display: "block"}}>
                    <input value={0} type="checkbox"
                           name={name}
                           hidden={true}
                    />
                    <input value={1} type="checkbox"
                           name={name}
                           checked={!!value}
                           id={name}
                           onChange={(e) => {
                               const val = value === 1 ? 0 : 1;
                               onChange(name, val);
                           }}/>
                </label>
            </div>
        </div>
    </div>
}