import React, {Fragment} from "react"

export const Button = (props) => {
    const {title, className, icon, ...rest} = props;
    const cls = ['btn', className || 'btn-default'].join(' ');
    const iconEl = icon ? <Fragment><i className={icon}/>&nbsp;</Fragment> : null;
    return <button
        className={cls}
        {...rest}>
        {iconEl}
        {title}
    </button>
}