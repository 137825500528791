// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Chart from "chart.js/auto"
Chart.defaults.plugins.tooltip.callbacks.footer = (tooltipItems) => {
    let sum = 0;
    tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y;
    });
    return 'Total: ' + sum;
}

import "chartkick/chart.js"

import "./confirm_dialog"

Turbolinks.start();
ActiveStorage.start();
// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
