import React from 'react';

export const getCsrfParams = () => {
    const csrfParam = $('meta[name=csrf-param]').attr('content');
    const csrfToken = $('meta[name=csrf-token]').attr('content');
    return {
        csrfParam: csrfParam,
        csrfToken: csrfToken
    }
}

export const CsrfInput = () => {
    const {csrfParam, csrfToken} = getCsrfParams();

    return <input type={"hidden"} name={csrfParam} value={csrfToken}/>;
}