import React, {useState} from "react";
import {Button} from "../Button";
import {getCsrfParams} from "../CsrfInput";

export const QueryInput = ({onSubmit, isSending}) => {
    const [query, setQuery] = useState("");
    const [useBulk, setUseBulk] = useState(false);


    const updateQuery = (e) => {
        setQuery(e.target.value);
    }

    const toggleBulk = (e) => {
        setUseBulk((prevState) => {
            return !prevState;
        });
    }

    const onSubmitClick = (e) => {
        e.preventDefault();
        const encoded = btoa(query);
        onSubmit({
            encodedQuery: encoded,
            useBulk: useBulk
        });
    }

    return (
        <form>
            <div className="form-group">
                <h5>SOQL Query</h5>
                <textarea
                    className="form-control"
                    rows="5"
                    onChange={updateQuery}
                    value={query}></textarea>
            </div>
            <div className="checkbox">
                <label>
                    <input type={"checkbox"} onChange={toggleBulk}/> Use Bulk API
                </label>
            </div>
            <div className="form-group">
                <Button disabled={isSending} className={"pull-right"}
                        title={isSending ? "Loading records..." : "Send query"} onClick={onSubmitClick}/>
            </div>
        </form>
    )
}