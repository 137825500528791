import React, {Fragment, useState, useEffect} from "react";
import {Select} from "./Select";
import {TimeAndZone} from "./TimeAndZone";
import {Button} from "./Button";
import {Checkbox} from "./Checkbox";
import {CsrfInput} from "./CsrfInput";
import {ConditionInput} from "./ConditionInput";
import {AdminParams} from "./AdminParams";
import {Section} from "./Section";
import {Input} from "./Input";

export const ArchiveJobWizard = (props) => {
    let {
        object_types,
        frequency_periods,
        weekdays,
        timezones,
        model_name,
        initial_data,
        days_of_month,
        operators,
        initial_columns = [],
        errors,
        date_time_functions,
        date_time_operators,
        admin_params_defaults = {},
        user_role
    } = props;

    if (errors == null) {
        errors = {};
    }

    const [columns, setColumns] = useState(initial_columns);

    const initialAdminParams = Object.keys(initial_data).reduce((memo, key) => {
        if (key.startsWith("admin_")) {
            memo[key] = initial_data[key];
        }
        return memo;
    }, {});
    const [formValues, setFormValues] = useState({
        is_active: initial_data.is_active ? 1 : 0,
        'object_types[]': initial_data.object_type || [],
        'conditions[]': initial_data.id ? initial_data.condition || [["", "", ""]] : [["", "", ""]],
        frequency_period: initial_data.frequency_period || 'month',
        day_of_month: initial_data.day_of_month || 1,
        weekday: initial_data.weekday,
        at: initial_data.at,
        tz: initial_data.tz,
        run_now: initial_data.run_now,
        dry_run: initial_data.dry_run,
        description: initial_data.description,
        ...initialAdminParams
    });

    let action = `/admin/${model_name}/submit_job`;
    let method = 'post';
    if (initial_data.id) {
        action = `/admin/${model_name}/${initial_data.id}/edit_schedule`;
    }

    const changeFormValue = (name, value) => {
        setFormValues({
            ...formValues, [name]: value
        });
    };

    const onConditionChange = (idx, condition) => {
        const conditions = [...formValues["conditions[]"]];
        conditions[idx] = condition;
        changeFormValue('conditions[]', conditions);
    };

    const onConditionRemove = (idx) => {
        let conditions = [...formValues["conditions[]"]];
        conditions.splice(idx, 1);
        if (conditions.length === 0) {
            conditions = [["", "", ""]];
        }
        changeFormValue('conditions[]', conditions);
    };

    const onConditionAdd = (idx) => {
        const conditions = [...formValues["conditions[]"]];
        conditions.push(["", "", ""]);
        changeFormValue('conditions[]', conditions);
    };

    useEffect(() => {
        const newValue = formValues['object_types[]'] || [];
        if (newValue.length > 0) {
            let queryString = newValue.map(v => {
                return 'sf_objects[]=' + v;
            });
            queryString.push('skip_external=1')
            queryString = queryString.join('&');
            const path = '/object_metadata/bulk_columns_rich.json?' + queryString;
            fetch(path).then(response => {
                if (response.ok) {
                    response.json().then(columns => {
                        columns = columns.filter((c) => {
                            return !c.name.startsWith('_');
                        })
                        setColumns(columns);
                    })
                }
            })
        } else {
            setColumns([]);
        }
    }, [formValues['object_types[]']]);

    useEffect(() => {
        if (columns.length > 0) {
            let conditions = [...formValues['conditions[]']].filter(cond => {
                return columns.some(col => col.name === cond[0]);
            });
            if (conditions.length === 0) {
                conditions = [["", "", ""]];
            }
            setFormValues({
                ...formValues, ['conditions[]']: conditions
            });
        }
    }, [columns]);

    const daysOfMonthSelection = formValues['frequency_period'] === 'month' ? <Select
        name={"day_of_month"}
        label={"Day of month"}
        options={days_of_month}
        value={formValues["day_of_month"]}
        onChange={changeFormValue}/> : null;

    const weekdaySelection = formValues['frequency_period'] === 'week' ? <Select
        name={"weekday"}
        label={"Weekday"}
        options={weekdays}
        value={formValues["weekday"]}
        onChange={changeFormValue}/> : null;

    let timeSelection = null;
    if (['month', 'week', 'day', 'hour'].includes(formValues['frequency_period'])) {
        timeSelection = <TimeAndZone
            timeLabel={"At time"}
            timeValue={formValues["at"]}
            timeName={"at"}
            onTimeChange={changeFormValue}
            zoneLabel={"Timezone"}
            zoneName={"tz"}
            zoneValue={formValues["tz"]}
            timezones={timezones}
            onZoneChange={changeFormValue}
        />

    }

    let submitText = 'Save schedule';

    let infoText = "";
    if (formValues["frequency_period"]) {
        const fp = formValues["frequency_period"];
        if (['month', 'week', 'day', 'hour'].includes(fp)) {
            infoText += `The job will run every ${fp}`;
            if (fp === 'month') {
                infoText += ` on day ${formValues["day_of_month"] || 1}`;
            }
        } else {
            infoText += `The job will run every ${fp}`
        }

        if (fp === "week" && formValues["weekday"]) {
            infoText += ` on ${formValues["weekday"]}`;
        }

        let timeAt = '00:00';
        if (fp === "hour") {
            timeAt = '**:00';
        }
        let timeZone = 'UTC';
        if (formValues["at"]) {
            timeAt = formValues["at"];
        }

        if (formValues["tz"]) {
            timeZone = formValues["tz"];
        }

        infoText += ` at ${timeAt} ${timeZone}`;
    }

    if (!formValues["is_active"]) {
        infoText = 'The job will not run while deactivated';
        submitText = 'Save';
    }

    if (formValues["run_now"]) {
        infoText = 'The job will run immediately after submission';
        submitText = 'Submit job';
        if (formValues["dry_run"]) {
            infoText += ' and will not modify any records'
        }
    }

    let adminParams = [];
    if (user_role === "admin") {
        adminParams = [
            "admin_archive_row_limit",
            "admin_archive_batch_size",
            "admin_archive_bucket_size",
            "admin_batch_delete_concurrency_mode",
            "admin_batch_update_concurrency_mode",
            "admin_batch_query_limit",
            "admin_delete_bucket_size",
            "admin_update_bucket_size",
            "admin_overwrite_archive_duplicates"
        ];
    }

    return <div className={"row"}>
        <div className={"col-xs-12"}>
            <form className={"form-horizontal denser"} style={{marginTop: 0}} action={action} method={method}
                  noValidate={true}>
                <CsrfInput/>
                <Section>Query configuration</Section>
                <Select
                    required={true}
                    name={"object_types[]"}
                    label={"Objects"}
                    options={object_types}
                    multi={true}
                    value={formValues["object_types[]"]}
                    errors={errors["object_types[]"]}
                    onChange={changeFormValue}/>
                <ConditionInput
                    name={"conditions[]"}
                    label={"Condition"}
                    conditions={formValues["conditions[]"]}
                    operators={operators}
                    columns={columns}
                    dateTimeFunctions={date_time_functions}
                    dateTimeOperators={date_time_operators}
                    onChange={onConditionChange}
                    onRemove={onConditionRemove}
                    onAdd={onConditionAdd}
                />
                {!formValues["run_now"] ? <Fragment>
                    <Section>Schedule configuration</Section>
                    {formValues["run_now"] ? null : <Checkbox
                        name={"is_active"}
                        label={"Is active"}
                        value={formValues["is_active"]}
                        onChange={changeFormValue}
                    />}
                    <Input
                        labelWidth={2}
                        inputWidth={10}
                        name={'description'}
                        label={'Description'}
                        onChange={changeFormValue}
                        value={formValues["description"]}
                        placeholder={"Enter description here"}
                    />
                    <Select
                        required={true}
                        name={"frequency_period"}
                        label={"Run every"}
                        options={frequency_periods}
                        value={formValues["frequency_period"]}
                        errors={errors["frequency_period"]}
                        onChange={changeFormValue}/>
                    {daysOfMonthSelection}
                    {weekdaySelection}
                    {timeSelection}
                </Fragment> : null}
                <Checkbox
                    name={"run_now"}
                    label={"Run now without scheduling"}
                    value={formValues["run_now"]}
                    onChange={changeFormValue}
                />
                {!!formValues["run_now"] ? <Checkbox
                    name={"dry_run"}
                    label={"Dry run"}
                    value={formValues["dry_run"]}
                    onChange={changeFormValue}
                /> : null}
                <div className={"col-sm-12"}>
                    {infoText !== '' ? <div className={"alert alert-info"}>
                        {infoText}
                    </div> : null}
                </div>
                <AdminParams adminParams={adminParams} onChange={changeFormValue}
                             formValues={formValues} placeholders={admin_params_defaults}/>
                <div className={"form-group form-actions"}>
                    <div className={"col-sm-offset-2 col-sm-10"}>
                        <Button
                            title={submitText}
                            type={"submit"}
                            className={"btn-primary"}
                            icon={"icon-white icon-ok"}
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>;
};