import React, {useState} from "react";
import {Button} from "../Button";
import {getCsrfParams} from "../CsrfInput";

const getTableHeader = (sampleRecord) => {
    return Object.keys(sampleRecord).filter((key) => {
        return key !== "attributes";
    });
}

const ResultTable = ({onFetchNextPage, result, isSending, recordIdx}) => {
    if ((result.done === true || !!result.nextRecordsUrl) && isSending === false) {
        const {totalSize, records, nextRecordsUrl} = result;
        const flattenedResult = flattenResult(records);
        const currentSize = records.length;
        let header = [];
        if (flattenedResult.length > 0) {
            header = getTableHeader(flattenedResult[0])
            header = ["Row Number", ...header];
        }
        let caption = `Showing ${currentSize}/${totalSize} records`;
        if (currentSize === 0) {
            caption = `Query returned ${totalSize} records`;
        }
        if (recordIdx > 1) {
            caption += ` after offset ${recordIdx}`;
        }
        if (!!nextRecordsUrl) {
            caption = <span>
                    {caption}, click <a style={{cursor: 'pointer'}} onClick={onFetchNextPage(nextRecordsUrl)}>here</a> to load next page
                </span>
        }
        return <React.Fragment>
            <table className="table table-condensed">
                <caption>{caption}</caption>
                <thead>
                <tr>
                    {header.map((h, idx) => <th key={idx}>{h}</th>)}
                </tr>
                </thead>
                <tbody>
                {flattenedResult.map((r, idx) => {
                    r["Row Number"] = recordIdx + idx;
                    return <tr key={idx}>{header.map((h, idx2) => {
                        return <td style={{maxWidth: 'none'}} key={idx2}>{r[h]}</td>;
                    })}</tr>;
                })}
                </tbody>
            </table>
            {!!nextRecordsUrl ? <Button
                disabled={isSending}
                onClick={onFetchNextPage(nextRecordsUrl)}
                title={isSending ? "Loading next page..." : "Load next page"}/> : null}
        </React.Fragment>;
    }

    if (Array.isArray(result)) {
        return <p style={{color: 'red'}}>{result[0].message}</p>
    }

    return null;
}

const flattenResult = (result) => {
    return result.map((row) => {
        return flattenObject(row);
    });
}

const flattenObject = (obj, resultMemo = {}, memoKey) => {
    const objKeys = Object.keys(obj).filter(k => k !== "attributes");
    objKeys.forEach((ok) => {
        let finalKey = ok;
        if (memoKey != null) {
            finalKey = memoKey + "." + ok;
        }
        const rowObject = obj[ok];
        const strVal = String(rowObject);
        if (strVal === "[object Object]") {
            return flattenObject(rowObject, resultMemo, finalKey);
        } else {
            resultMemo[finalKey] = strVal;
        }
    });
    return resultMemo;
}

export const QueryOutput = ({result, onFetchNextPage, isSending, recordIdx}) => {
    return (<React.Fragment>
        <h5>Query Output</h5>
        <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
            {result != null ?
                <ResultTable
                    result={result}
                    onFetchNextPage={onFetchNextPage}
                    isSending={isSending}
                    recordIdx={recordIdx}/> : null}
        </div>
    </React.Fragment>);
}