import React, {useState} from "react";
import {Input} from "../Input";
import {Button} from "../Button";
import {getCsrfParams} from "../CsrfInput";
import {TreeOutput} from "../TreeOutput/TreeOutput";
import {DirectoryItem} from "../TreeOutput/DirectoryOutput";
import {ResultOutput} from "../TreeOutput/ApiResultOutput";

export const RestInterface = (props) => {
    const {basePath = "", orgId} = props;
    const [requestUrl, setRequestUrl] = useState(basePath);
    const [isSending, setIsSending] = useState(false);
    const [requestResult, setRequestResult] = useState(null);

    const sendRequest = async (e) => {
        e.preventDefault();
        const requestBody = {
            org_id: orgId,
            rest_url: requestUrl
        }
        setIsSending(true);
        const {csrfParam, csrfToken} = getCsrfParams();
        requestBody[csrfParam] = csrfToken;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(requestBody)
        };
        const result = await fetch('/object_metadata/rest_request', requestOptions);
        const jsonResult = await result.json();

        setIsSending(false);
        setRequestResult(jsonResult);
    }

    return (<React.Fragment>
        <div className={"row"}>
            <div className={"col-xs-12"}>
                <form>
                    <div className={"form-group"}>
                        <label htmlFor="url">Url</label>
                        <input
                            type="url"
                            className="form-control"
                            id="url"
                            placeholder="url"
                            value={requestUrl}
                            onChange={(e) => {
                                setRequestUrl(e.target.value);
                            }}/>
                    </div>
                    <Button
                        title={isSending ? "Sending request" : "Submit"}
                        className={"pull-right"}
                        onClick={sendRequest}
                        disabled={isSending}/>
                </form>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-xs-12"}>
                <h4 style={{borderBottom: "1px solid #eee"}}>Output</h4>
                {requestResult != null ? <ResultOutput result={requestResult}/> : null}
            </div>
        </div>
    </React.Fragment>);
}

