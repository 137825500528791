import React, {useEffect, useState} from 'react';

const ARCHIVE_TYPE = "archive";
const UNARCHIVE_RETENTION_CHECK = "unarchive_retention_check";
const UNARCHIVE_TYPE = "unarchive";
const ORG_METADATA_TYPE = "org_metadata";
const CSV_EXPORT_TYPE = "csv_export";
const BULK_UNARCHIVE_JOB_TYPE = "unarchive_bulk";

const PrettyList = ({list}) => {

    const copyToCb = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(list.join(","));
    }

    let prettyList = list.join(",");
    const limit = 10;
    if (list.length > limit) {
        let more = list.length - limit;
        list = list.map((listItem) => {
            if (Array.isArray(listItem)) {
                return listItem.join(":")
            } else {
                return listItem;
            }
        })
        prettyList = [
            <span key={0}>{list.slice(0, limit).join(",")}</span>,
            <span key={1}>... and {more} more </span>,
            <button key={2} className={"btn btn-xs btn-default"}
                    onClick={copyToCb}>Copy to clipboard</button>
        ];
    }
    return prettyList;
}

const MemoryOutput = ({title, slot_name, memories, no_parse = false}) => {
    const slotRows = memories.filter((memory) => memory.slot_name === slot_name);
    let outputRows = [];

    if (slotRows && slotRows.length > 0) {
        slotRows.forEach((row, idx) => {
            if (row.content) {
                if (no_parse) {
                    outputRows.push(<p key={idx} style={{wordBreak: 'break-all'}}>
                        <a href={`/object_metadata/csv_export/${row.content}`}>{row.object_name}</a>
                    </p>)
                } else {
                    const idList = JSON.parse(row.content);
                    if (idList.length > 0) {
                        outputRows.push(<p key={idx} style={{wordBreak: 'break-all'}}>
                            <strong>{row.object_name}&nbsp;({idList.length})&nbsp;</strong>
                            <PrettyList list={idList}/>
                        </p>);
                    }
                }

            }
        });
    }
    return <div>
        <h4>
            {title}&nbsp;({outputRows.length})
        </h4>
        {outputRows.length > 0 ? outputRows : "-"}
    </div>;
}

const DryRunStatus = (props) => {
    const {job_type} = props.job;
    let pendingIds = null;
    let dryRunListName = "ARCHIVE_DRY_RUN_LIST";
    let dryRunLabel = 'Objects to be archived';
    if (job_type === UNARCHIVE_TYPE || job_type === BULK_UNARCHIVE_JOB_TYPE) {
        dryRunListName = "UNARCHIVE_DRY_RUN_LIST";
        dryRunLabel = 'Objects to be unarchived';
        pendingIds = <MemoryOutput
            title={"Pending Objects"}
            slot_name={"PENDING_UNARCHIVE_ID_LIST"}
            memories={props.memories}/>
    }
    return <div>
        {pendingIds}
        <MemoryOutput
            title={dryRunLabel}
            slot_name={dryRunListName}
            memories={props.memories}/>
    </div>;
}


const ArchiveStatus = (props) => {
    return <div>
        <MemoryOutput
            title={"Archived Objects"}
            slot_name={"SUCCESS_ARCHIVE_ID_LIST"}
            memories={props.memories}/>
        <MemoryOutput
            title={"Failed Objects"}
            slot_name={"ARCHIVE_UNLOCK_ERROR_LIST"}
            memories={props.memories}/>
    </div>;
}

const UnarchiveStatus = (props) => {
    return <div>
        <MemoryOutput
            title={"Pending Objects"}
            slot_name={"PENDING_UNARCHIVE_ID_LIST"}
            memories={props.memories}/>
        <MemoryOutput
            title={"Unarchived Objects"}
            slot_name={"SUCCESS_UNARCHIVE_ID_LIST"}
            memories={props.memories}/>
    </div>;
}

const BulkUnarchiveStatus = (props) => {
    return <div>
        <MemoryOutput
            title={"Unarchived Objects"}
            slot_name={"SUCCESS_UNARCHIVE_ID_LIST"}
            memories={props.memories}/>
    </div>;
}

const CsvExportStatus = (props) => {
    return <div>
        <MemoryOutput
            title={"Exported files"}
            slot_name={"EXPORT_FILE_LINK"}
            memories={props.memories}
            no_parse={true}/>
    </div>;
}

const Section = (props) => (<h3>{props.title}</h3>);

const ToggleButton = ({showDetails, setShowDetails}) => (
    <button className={"btn btn-xs btn-default"}
            onClick={() => {
                setShowDetails(!showDetails)
            }}>
        {showDetails ? 'Less Details' : 'More Details'}
    </button>
);

const textWrap = (content, maxLength) => {
    let finalContent = content;
    if (content.length > maxLength) {
        finalContent = content.slice(0, maxLength) + "...";
    }
    return finalContent;
}

const StepsList = ({steps}) => {
        const [showDetails, setShowDetails] = useState(false);
        const doneSteps = steps.reduce((memo, step) => {
            if (step.is_done) {
                memo++;
            }
            return memo;
        }, 0);
        const totalSteps = steps.length;
        let list = null;

        if (totalSteps === 0) {
            return null;
        }

        if (showDetails) {
            const steps_list = steps.map((step, idx) => {
                const {is_done, command, arguments: args} = step;
                const argsList = JSON.parse(args);
                const argsLabels = argsList.map((arg, idx) => {
                    const argStr = `${arg}`;
                    return <span key={idx}>&nbsp;<span
                        title={argStr}
                        style={{
                            maxWidth: "100%"
                        }}
                        className="badge">{textWrap(argStr, 50)}</span></span>
                });
                const labelName = is_done ? 'success' : 'primary';
                return <p key={idx}>
                <span className={`label label-${labelName}`}>
            {command}
                </span>&nbsp;{argsLabels}
                </p>;
            });
            list = <div>
                <div style={{marginBottom: '15px'}}>
                    <ToggleButton showDetails={showDetails} setShowDetails={setShowDetails}/>
                </div>
                <div>{steps_list}</div>
                <div style={{maringTop: '15px'}}>
                    <ToggleButton showDetails={showDetails} setShowDetails={setShowDetails}/>
                </div>
            </div>;
        } else {
            if (totalSteps > 0) {
                let stepsSummary = `Running step ${doneSteps + 1} of ${totalSteps}`;
                if (doneSteps === totalSteps) {
                    stepsSummary = "All Steps Finished"
                }

                list = <div>
                    <span>{stepsSummary}&nbsp;</span>
                    <ToggleButton showDetails={showDetails} setShowDetails={setShowDetails}/>
                </div>;
            }
        }
        return <div>
            {list}
        </div>;
    }
;

const StepsProgress = ({steps}) => {
    const totalSteps = steps.length;
    const doneSteps = steps.reduce((memo, step) => {
        if (!!step.is_done) {
            memo++;
        }
        return memo;
    }, 0);
    const percentDone = Math.round(doneSteps / totalSteps * 100);
    const progressBarStyle = {width: `${percentDone}%`};
    const progressBarStatus = `${percentDone}% complete`;
    let progressBarClass = `progress-bar`;
    if (percentDone !== 100) {
        progressBarClass += " progress-bar-striped active";
    }

    return <div className="progress">
        <div className={progressBarClass} role="progressbar" aria-valuenow={percentDone}
             aria-valuemin="0" aria-valuemax="100" style={progressBarStyle}>
            <span className="sr-only">{progressBarStatus}</span>
        </div>
    </div>;
}

const SpecificDetails = (props) => {
    const {job_type} = props.job;
    const args = JSON.parse(props.job["arguments"]);
    if (!!args.dry_run) {
        return <DryRunStatus {...props} />;
    } else if (job_type === ARCHIVE_TYPE || job_type === UNARCHIVE_RETENTION_CHECK) {
        return <ArchiveStatus {...props} />
    } else if (job_type === UNARCHIVE_TYPE) {
        return <UnarchiveStatus {...props}/>;
    } else if (job_type === CSV_EXPORT_TYPE) {
        return <CsvExportStatus {...props}/>;
    } else if (job_type == BULK_UNARCHIVE_JOB_TYPE) {
        return <BulkUnarchiveStatus {...props}/>;
    } else {
        return null
    }
}

const StatusTag = ({status}) => {
    const statusColorMap = {
        "queued": "warning",
        "running": "primary",
        "success": "success",
        "error": "danger",
        "completed_with_errors": "info",
        "canceled": "info"
    };
    const labelClass = `label label-${statusColorMap[status]}`;
    return <span className={labelClass}>{status}</span>;
}

const Error = ({error}) => {
    if (error) {
        return <div>
            <Section title={"Error"}/>
            <p>{error}</p>
        </div>;
    } else {
        return null;
    }
}

const jobStartedAt = (job) => {
    if (!!job.started_at) {
        return new Date(job.started_at).toLocaleString();
    } else {
        return "-";
    }
}

const jobFinishedAt = (job) => {
    if (!["queued", "running"].includes(job.status)) {
        return new Date(job.updated_at).toLocaleString();
    } else {
        return "-";
    }
}

const jobCanceledAt = (job) => {
    if (!!job.canceled_at && ["queued", "running", "canceled"].includes(job.status)) {
        return new Date(job.canceled_at).toLocaleString();
    } else {
        return "-";
    }
}

export const JobDetails = (props) => {
    const {job: initialJob, steps: initialSteps, memories: initialMemories} = props;
    const [jobData, setJobData] = useState({
        job: initialJob,
        steps: initialSteps,
        memories: initialMemories
    });
    const {job, steps} = jobData;

    const standardProps = [{
        name: 'Status',
        value: <StatusTag status={job.status}/>
    }, {
        name: 'Job Type',
        value: job.job_type
    }, {
        name: 'Job Id',
        value: job.job_id
    }, {
        name: 'Queued at',
        value: new Date(job.created_at).toLocaleString()
    }, {
        name: 'Started at',
        value: jobStartedAt(job)
    }, {
        name: 'Finished at',
        value: jobFinishedAt(job)
    }, {
        name: 'Canceled at',
        value: jobCanceledAt(job)
    }, {
        name: 'Duration',
        value: job.duration_pretty
    }];
    const argProps = [];
    const args = JSON.parse(job.arguments);
    for (const argName in args) {
        let prettyName = argName;
        if (argName.startsWith("admin_")) {
            prettyName = argName.replace("admin_", "").toUpperCase();
        }
        argProps.push({
            "name": prettyName,
            "value": args[argName]
        })
    }

    const statusPath = `/admin/archive_job_status/${job.id}/job_details.json`
    useEffect(() => {
        const interval = setInterval(() => {
            fetch(statusPath).then((res) => {
                if (res.ok) {
                    res.json().then(status => {
                        setJobData(status);
                    });
                }
            });
        }, 1500);
        return () => clearInterval(interval);
    }, [])

    return <div className={"row"}>
        <div className={"col-xs-12"} style={{wordBreak: 'break-all'}}>
            <Section title={"Basic Info"}/>
            {standardProps.map((sp, idx) => (
                <p key={idx}><strong>{sp.name}:</strong> {sp.value}</p>
            ))}
            <Section title={"Arguments"}/>
            {argProps.map((sp, idx) => (
                <p key={idx}><strong>{sp.name}:</strong> {sp.value.toString()}</p>
            ))}
        </div>
        <div className={"col-xs-12"}>
            <Error error={job.error}/>
        </div>
        <div className={"col-xs-12"}>
            <SpecificDetails {...jobData}/>
        </div>
        <div className={"col-xs-12"} style={{paddingBottom: '20px'}}>
            <Section title={"Progress"}/>
            <StepsProgress steps={steps}/>
            <StepsList steps={steps}/>
        </div>
    </div>;
}