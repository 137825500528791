import React, {useState} from "react";
import {Input} from "./Input";
import {Button} from "./Button";
import {Section} from "./Section";

export const AdminParams = (props) => {
    const {adminParams, onChange, formValues, placeholders} = props;
    const [show, updateShow] = useState(false);
    if (!!adminParams && Array.isArray(adminParams) && adminParams.length > 0) {
        return <div>
            <Section>Advanced</Section>
            <div className={"col-sm-12"} style={{height: show ? 'auto' : '0px', paddingTop: '1px', overflow: 'hidden'}}>
                {adminParams.map(ap => <Input
                    labelWidth={3}
                    inputWidth={9}
                    key={ap}
                    name={ap}
                    label={ap.replace('admin_', '').toUpperCase()}
                    onChange={onChange}
                    value={formValues[ap]}
                    placeholder={placeholders[ap]}
                />)}
            </div>
            <Button
                title={show ? 'Hide' : 'Click to reveal'}
                className={'btn-sm btn-default'}
                onClick={(e) => {
                    e.preventDefault();
                    updateShow(!show);
                }}
            />
        </div>;
    }
    return null;
}