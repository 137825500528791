import React from "react";
import {NavPills} from "../NavPills/NavPills";
import {NavPill} from "../NavPills/NavPill";
import {SoqlQuery} from "./SoqlQuery";
import {RestInterface} from "./RestInterface";
import {BulkApiJobStatus} from "./BulkApiJobStatus";


export const QueryInterface = (props) => {
    const sp = new URLSearchParams(window.location.search)
    const activeIdx = sp.get('jobId') != null ? 2 : 0;
    const {orgId, archiveObjectNames, restBasePath} = props;

    return (
        <React.Fragment>
            <NavPills activeIdx={activeIdx}>
                <NavPill title={"SOQL Query"}>
                    <SoqlQuery orgId={orgId} archiveObjectNames={archiveObjectNames}/>
                </NavPill>
                <NavPill title={"REST Interface"}>
                    <RestInterface basePath={restBasePath} orgId={orgId}/>
                </NavPill>
                <NavPill title={"Bulk API Job Status"}>
                    <BulkApiJobStatus orgId={orgId}/>
                </NavPill>
            </NavPills>
        </React.Fragment>
    )
}