import React, {useEffect, useState} from "react";
import {Checkbox} from "./Checkbox";


export const OrgPermissions = (props) => {
    const {orgId, permissionsReport} = props;
    const [header, ...rest] = permissionsReport;
    const [scopedObject, setScopedObject] = useState(null);

    const onObjectClicked = (objectName) => {
        setScopedObject(objectName);
    }

    const clearScopedObject = () => {
        setScopedObject(null);
    }

    return (<React.Fragment>
        {scopedObject != null ?
            <ScopedObjectTable orgId={orgId} sobject={scopedObject} onClearClick={clearScopedObject}/> :
            <AllObjectsTable orgId={orgId} permissions={rest} onObjectClicked={onObjectClicked}/>}
    </React.Fragment>)
}

const ScopedObjectTable = (props) => {
    const {orgId, sobject, onClearClick} = props;
    const [sobjectPermissions, setSobjectPermissions] = useState({});

    const permissionPath = `/object_metadata/sobject_permissions/${orgId}/${sobject}`
    useEffect(() => {
        fetch(permissionPath).then((res) => {
            if (res.ok) {
                res.json().then(permissions => {
                    setSobjectPermissions(permissions);
                });
            }
        });
    }, [])

    const handleClick = (e) => {
        e.preventDefault();
        onClearClick();
    }

    if (!sobjectPermissions) {
        return <p>loading permissions...</p>;
    }

    return (<React.Fragment>
        <a href={null} onClick={handleClick} style={{cursor: 'pointer'}}>Back to list</a>
        <MainTitle title={sobject}></MainTitle>
        <ObjectPermissionsTable objectPermissions={sobjectPermissions['objectPermissions']}/>
        <RecordTypePermissionsTable recordTypePermissions={sobjectPermissions['recordTypePermissions']}/>
        <FieldPermissionsTable fieldPermissions={sobjectPermissions['fieldPermissions']}/>
    </React.Fragment>);
}

const ObjectPermissionsTable = (props) => {
    const {objectPermissions} = props;

    const objectPermissionsRow = objectPermissions != null ? objectPermissions[0] : null;

    const permissionKeys = ['obj_permissions_create', 'obj_permissions_delete', 'obj_permissions_edit', 'obj_permissions_modify_all_records', 'obj_permissions_read', 'obj_permissions_view_all_records'];

    if (!objectPermissionsRow) {
        return null;
    }

    return (<React.Fragment>
        <SubTitle title={"Object Permissions"}/>
        <table className={"table table-condensed table-striped"}>
            <thead>
            <tr>
                <th>
                    Create
                </th>
                <th>
                    Delete
                </th>
                <th>
                    Edit
                </th>
                <th>
                    Modify All Records
                </th>
                <th>
                    Read
                </th>
                <th>
                    View All Records
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                {permissionKeys.map((pk) => {
                    return <td key={pk}>{objectPermissionsRow[pk].toString()}</td>
                })}
            </tr>
            </tbody>
        </table>
    </React.Fragment>);
}

const RecordTypePermissionsTable = (props) => {
    const {recordTypePermissions} = props;
    const [showOnlyWithIssues, setShowOnlyWithIssues] = useState(false);

    const toggleWithIssues = () => {
        setShowOnlyWithIssues(prevState => !prevState);
    }

    if (recordTypePermissions == null) {
        return null;
    }

    return (<React.Fragment>
        <SubTitle title={"Record Type Permissions"}/>
        <div style={{padding: '10px 0px'}}>
            <input style={{margin: '0 5px 0 5px'}}
                   type="checkbox"
                   id="showIssues"
                   name="showIssues"
                   checked={showOnlyWithIssues}
                   onChange={toggleWithIssues}/>
            <label htmlFor="showIssues">Show only missing record types</label>
        </div>
        <table className={"table table-condensed table-striped"}>
            <thead>
            <tr>
                <th>
                    Record Type Name
                </th>
                <th>
                    Available
                </th>
            </tr>
            </thead>
            <tbody>
            {recordTypePermissions.filter((rtp) => {
                if (showOnlyWithIssues) {
                    return rtp["available"] === false;
                }
                return true;
            }).map((rtp) => {
                return <tr key={rtp['record_type_name']}>
                    <td>{rtp['record_type_name']}</td>
                    <td style={{color: rtp["available"] === false ? 'red' : null}}>{rtp['available'].toString()}</td>
                </tr>
            })}
            </tbody>
        </table>
    </React.Fragment>);
}

const FieldPermissionsTable = (props) => {
    const {fieldPermissions} = props;
    const [showOnlyWithIssues, setShowOnlyWithIssues] = useState(false);

    const toggleWithIssues = () => {
        setShowOnlyWithIssues(prevState => !prevState);
    }

    const fieldPermKeys = ['field', 'read', 'edit', 'createable', 'permissionable', 'name_field', 'calculated', 'calculated formula', 'sf_type'];

    if (!fieldPermissions) {
        return null;
    }

    return (<React.Fragment>
            <SubTitle title={"Field Permissions"}/>
            <div style={{padding: '10px 0px'}}>
                <input style={{margin: '0 5px 0 5px'}}
                       type="checkbox"
                       id="showIssues"
                       name="showIssues"
                       checked={showOnlyWithIssues}
                       onChange={toggleWithIssues}/>
                <label htmlFor="showIssues">Show only fields with issues</label>
            </div>
            <table className={"table table-condensed table-striped"}>
                <thead>
                <tr>
                    {fieldPermKeys.map((fpk) => {
                        return <th key={fpk}>{fpk}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {fieldPermissions.filter((fp) => {
                    if (showOnlyWithIssues) {
                        return fp["has_issues"];
                    }
                    return true;
                }).map((fp, idx) => {
                    return <tr key={idx}>
                        {fieldPermKeys.map((fpk) => {
                            const value = fp[fpk];
                            return <td key={fpk}
                                       style={{color: fp["has_issues"] ? 'red' : null}}>{value !== undefined && value !== null ? value.toString() : null}</td>
                        })}
                    </tr>
                })}
                </tbody>
            </table>
        </React.Fragment>
    )
}
const AllObjectsTable = (props) => {
    const {orgId, permissions, onObjectClicked} = props;
    const reportLink = `/object_metadata/permissions_report/${orgId}.csv`;

    const onObjectSelected = (objectName) => {
        return (e) => {
            e.preventDefault();
            onObjectClicked(objectName);
        }
    }

    return (<React.Fragment>
        <div style={{padding: '10px 5px'}}>
            <a href={reportLink} target="_blank">Download report in csv</a>
        </div>
        <table className={"table table-condensed table-striped"}>
            <thead>
            <tr>
                <th>
                    Object name
                </th>
                <th>
                    Missing Object Permissions
                </th>
                <th>
                    Missing Record Type Permissions
                </th>
                <th>
                    Missing Field Permissions
                </th>
            </tr>
            </thead>
            <tbody>
            {permissions.map((row, idx) => {
                const errorStyle = {color: 'red', fontWeight: 'bold'};

                return <tr key={idx}>
                    <td style={{cursor: 'pointer'}}><a style={row[2] + row[4] + row[6] > 0 ? errorStyle : null}
                                                       href={null}
                                                       onClick={onObjectSelected(row[0])}>{row[0]}</a>
                    </td>
                    <td style={row[2] > 0 ? errorStyle : null}>{row[2]}</td>
                    <td style={row[4] > 0 ? errorStyle : null}>{row[4]}</td>
                    <td style={row[6] > 0 ? errorStyle : null}>{row[6]}</td>
                </tr>;
            })}
            </tbody>
        </table>
    </React.Fragment>);
}

const MainTitle = (props) => {
    const {title} = props;
    return (
        <h2 style={{marginBottom: '20px'}}>{title}</h2>
    );
}

const SubTitle = (props) => {
    const {title} = props;
    return (
        <h4 style={{borderBottom: '1px solid #999'}}>{title}</h4>
    );
}

