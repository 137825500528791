import React, {useState, useEffect} from 'react';
import {SimpleSelect} from "./SimpleSelect";
import {SimpleInput} from "./SimpleInput";
import {Button} from "./Button";

const FunctionInput = (props) => {
    const {
        value,
        functionOptions = [],
        onChange
    } = props;

    const [selectedFunction, setSelectedFunction] = useState("");
    const [argumentValue, setArgumentValue] = useState("");
    const [hasArgument, setHasArgument] = useState(true);

    useEffect(() => {
        if (!!value) {
            const [functionName, functionArgument] = value.split(':');
            let existingFunction;
            let realFunctionName;
            if (!!functionArgument) {
                realFunctionName = [functionName, 'n'].join(':');
                existingFunction = functionOptions.some(fo => fo === realFunctionName);
            } else {
                realFunctionName = functionName
                existingFunction = functionOptions.some(fo => fo === functionName);
            }

            if (existingFunction) {
                if (!!functionArgument) {
                    setHasArgument(true);
                    setSelectedFunction(realFunctionName);
                    setArgumentValue(functionArgument);
                } else {
                    setHasArgument(false);
                    setSelectedFunction(functionName);
                }
            } else {
                setHasArgument(true);
                setArgumentValue(value);
            }
        }
    }, [value]);


    const onFunctionChange = (functionValue) => {
        if (!!functionValue) {
            setSelectedFunction(functionValue);
            const functionHasArgument = functionValue.split(':').length > 1;
            if (functionHasArgument) {
                setHasArgument(true);
            } else {
                setHasArgument(false);
                setArgumentValue('');
                onChange(functionValue);
            }
        } else {
            setSelectedFunction('');
            setHasArgument(true);
        }
    }

    const onValueChange = (argValue) => {
        console.log("argValue", argValue);
        if (!!argValue) {
            if (!!selectedFunction) {
                const functionWithoutArgument = selectedFunction.split(':')[0];
                const newValue = [functionWithoutArgument, argValue].join(":");
                setArgumentValue(newValue)
                onChange(newValue);
            } else {
                setArgumentValue(argValue);
                onChange(argValue);
            }
        } else {
            setArgumentValue("");
            onChange("");
        }
    }

    return <div className={"row"}>
        <div className={`col-sm-${hasArgument ? 6 : 12}`}>
            <SimpleSelect
                allowEmpty={true}
                value={selectedFunction}
                options={functionOptions.map(vf => ({label: vf, value: vf}))}
                emptyLabel={'-- No Function --'}
                onChange={onFunctionChange}
            />
        </div>
        {hasArgument ? <div className={'col-sm-6'}>
            <SimpleInput
                value={argumentValue}
                onChange={onValueChange}
            /></div> : null}
    </div>
}

const ConditionBlock = (props) => {
    const {
        idx,
        column,
        columnType,
        operator,
        value,
        columnOptions,
        operatorOptions,
        onChange,
        onRemove,
        dateTimeFunctions = [],
        dateTimeOperators = []
    } = props;

    const updateCondition = (position) => {
        return (newValue) => {
            let returnValue = [column, operator, value];
            returnValue[position] = newValue;
            onChange(returnValue);
        };
    };

    const handleRemove = (e) => {
        e.preventDefault();
        onRemove();
    }

    const [valueFunctions, setValueFunctions] = useState([]);

    useEffect(() => {
        if (columnType === "datetime") {
            let newDateTimeFunctions = []
            if (!!operator) {
                const validOperator = dateTimeOperators.some(op => op === operator);
                if (validOperator) {
                    newDateTimeFunctions = [...dateTimeFunctions];
                }
            }
            setValueFunctions(newDateTimeFunctions);
        } else {
            setValueFunctions([])
        }
    }, [column, columnType, operator]);

    return <div className={"row"} style={{marginBottom: '10px'}}>
        <div className={"col-sm-3"}>
            <SimpleSelect
                value={column}
                options={columnOptions}
                emptyLabel={'-- Column --'}
                onChange={updateCondition(0)}
            />
        </div>
        <div className={"col-sm-2"}>
            <SimpleSelect
                value={operator}
                options={operatorOptions}
                emptyLabel={'-- Operator --'}
                onChange={updateCondition(1)}
            />
        </div>
        <div className={"col-sm-4"}>
            {valueFunctions.length > 0 ? <FunctionInput
                value={value}
                onChange={updateCondition(2)}
                functionOptions={valueFunctions}
            /> : <SimpleInput
                value={value}
                onChange={updateCondition(2)}
            />}
        </div>
        <div className={"col-sm-3"}>
            <Button
                title={idx === 0 ? 'Clear' : 'Remove'}
                icon={'icon-remove'}
                onClick={handleRemove}
            />
        </div>
    </div>
};

export const ConditionInput = (props) => {
    let {
        name,
        label,
        conditions = [["", "", ""]],
        operators,
        columns = [],
        onAdd,
        onRemove,
        onChange,
        required = false,
        errors = [],
        dateTimeFunctions,
        dateTimeOperators
    } = props;

    const operatorOptions = operators.map(op => {
        return {
            label: op,
            value: op
        };
    });

    const columnOptions = columns.map(c => {
        return {
            label: c.name,
            value: c.name
        };
    });

    const updateCondition = (idx) => {
        return (condition) => {
            onChange(idx, condition);
        }
    };

    const handleRemove = (idx) => {
        return () => {
            onRemove(idx);
        };
    };

    const addNew = (e) => {
        e.preventDefault();
        onAdd();
    };

    const addEnabled = conditions.every((condition) => {
        const [column, operator, value] = condition;
        return !column || !operator || !value;
    });

    return <div className={"form-group control-group"}>
        <label className={"col-sm-2 control-label"} htmlFor={name}>{label}</label>
        <div className={`col-sm-10 controls ${errors.length > 0 ? "has-error" : ""}`}>
            <select style={{display: 'none'}} name={name} multiple={true} value={conditions} readOnly={true}>
                {conditions.map((c, idx) => {
                    return <option key={idx} value={c}/>
                })}
            </select>
            {conditions.map(([column, operator, ...rest], idx) => {
                const value = rest.join(',');
                let columnType = null;
                if (!!column) {
                    const targetColumn = columns.find(c => c.name === column);
                    if (!!targetColumn) {
                        columnType = targetColumn.type;
                    }
                }
                return <ConditionBlock
                    idx={idx}
                    key={idx}
                    column={column}
                    columnType={columnType}
                    operator={operator}
                    value={value}
                    columnOptions={columnOptions}
                    operatorOptions={operatorOptions}
                    dateTimeFunctions={dateTimeFunctions}
                    dateTimeOperators={dateTimeOperators}
                    onChange={updateCondition(idx)}
                    onRemove={handleRemove(idx)}
                />;
            })}
            <Button
                title={'Add'}
                disabled={addEnabled}
                icon={'icon-plus'}
                onClick={addNew}
            />
            {
                errors.length > 0 ? (errors.map((error, idx) => (
                    <span key={idx} className="help-block">{error}</span>
                ))) : (
                    <span className="help-block">{required ? 'Required' : 'Optional'}</span>
                )
            }
        </div>
    </div>;
}