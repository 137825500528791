import React, {useState} from "react";
import {SimpleSelect} from "../SimpleSelect";
import {TreeOutput} from "../TreeOutput/TreeOutput";
import {DirectoryOutput} from "../TreeOutput/DirectoryOutput";

export const ObjectExplorer = (props) => {
    const [objectData, setObjectData] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [objectCache, setObjectCache] = useState({});
    const {objectNames, orgId} = props;
    const describeBasePath = `/object_metadata/describe/${orgId}`

    const onSelect = async (objectName) => {
        if (objectName !== '') {
            let currentObjectData = objectCache[objectName];
            if (!!currentObjectData) {
                setObjectData(currentObjectData);
            } else {
                setIsFetching(true);
                const describeFullPath = `${describeBasePath}/${objectName}`;
                const response = await fetch(describeFullPath);
                if (response.ok) {
                    currentObjectData = await response.json();
                    setObjectCache((prevState) => {
                        prevState[objectName] = currentObjectData;
                        return prevState;
                    })
                    setObjectData(currentObjectData);
                    setIsFetching(false);
                }
            }
        } else {
            setObjectData(null);
        }
    }

    return (<React.Fragment>
        <h5>Object Explorer</h5>
        <SimpleSelect
            disabled={isFetching}
            onChange={onSelect}
            allowEmpty={true}
            options={objectNames.map(on => {
                return {
                    label: on,
                    value: on
                };
            })}
        />
        <div style={{paddingTop: '20px'}}>
            {isFetching ? <p style={{padding: '0px 10px'}}>Fetching metadata...</p> :
                <ObjectDescribeLayout objectData={objectData}/>}
        </div>
    </React.Fragment>)
}


const ObjectDescribeLayout = (props) => {
    const {objectData} = props;
    const [activeItem, setActiveItem] = useState(1);

    const onItemClick = (itemId) => {
        return (e) => {
            e.stopPropagation();
            setActiveItem((prevState) => {
                if (prevState === itemId) {
                    return 0;
                } else {
                    return itemId;
                }
            })

        }
    }

    if (objectData != null) {
        const {childRelationships, fields, recordTypeInfos} = objectData;
        return (<div className="list-group">
            <ObjectMetadataItem title={"Fields"} key={1} onClick={onItemClick(1)}
                                isActive={activeItem === 1} content={fields} titleKey={"name"} subtitleKey={"type"}/>
            <ObjectMetadataItem title={"Record Types"} key={2} onClick={onItemClick(2)}
                                isActive={activeItem === 2} content={recordTypeInfos} titleKey={"developerName"}/>
            <ObjectMetadataItem title={"Child Relationships"} key={3} onClick={onItemClick(3)}
                                isActive={activeItem === 3} content={childRelationships} titleKey={"childSObject"}
                                subtitleKey={"field"}/>
        </div>);
    }

    return null;
}

const ObjectMetadataItem = (props) => {
    const {title, content, isActive, onClick, titleKey, subtitleKey} = props;

    const panelType = isActive ? ' panel-primary' : 'panel-default';

    return (<div className={'panel ' + panelType}>
        <div className="panel-heading" onClick={onClick} style={{cursor: 'pointer'}}>
            {title} <span className="badge pull-right">{content.length}</span>
        </div>
        {
            isActive ? <div className="panel-body" style={{maxHeight: '300px', overflow: 'auto'}}>
                <DirectoryOutput items={content} titleKey={titleKey} subtitleKey={subtitleKey}/>
            </div> : null
        }
    </div>);
}
