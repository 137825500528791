import React, {useRef, useEffect} from "react";

export const Select = (props) => {
    const {name, label, options, onChange, value, required = false, multi = false, errors = []} = props;
    let optionsWithBlank = [...options];
    if (!multi) {
        optionsWithBlank = [["", ""], ...optionsWithBlank];
    }

    const jq = window.$;
    const selectRef = useRef(null);
    const selectDropdownRef = useRef(null);

    useEffect(() => {
        if (selectRef !== null && selectRef.current !== null) {
            if (selectDropdownRef == null || selectDropdownRef.current == null) {
                const select = jq(selectRef.current).find('[data-enumeration]');
                selectDropdownRef.current = jq(select[0]);
                const options = $(selectDropdownRef.current).data('options');
                if (multi) {
                    selectDropdownRef.current.filteringMultiselect(options);
                } else {
                    selectDropdownRef.current.filteringSelect(options);
                }

                if (value !== "" && value !== undefined) {
                    selectDropdownRef.current.val(value);
                }
            }
        }
    }, [selectRef]);

    useEffect(() => {
        if (selectDropdownRef !== null && selectDropdownRef.current !== null) {
            if (multi) {
                let selection = jq(selectRef.current).find('select.ra-multiselect-selection');
                selection.off('change');
                selection.change((e) => {
                    const opts = Array.from(e.target.options).map((opt) => opt.value);
                    onChange(name, opts);
                });
            } else {
                selectDropdownRef.current.off('change');
                selectDropdownRef.current.change((e) => {
                    onChange(name, e.target.value);
                });
            }
        }
    }, [selectDropdownRef, value, onChange]);


    return <div className={"form-group control-group"} ref={selectRef}>
        <label className={"col-sm-2 control-label"} htmlFor={name}>{label}</label>
        <div className={`col-sm-10 controls ${errors.length > 0 ? "has-error" : ""}`}>
            <select
                required={required}
                name={name}
                defaultValue={value}
                data-enumeration={true}
                data-options='{"regional":{"search":"Search"}}'
                style={{display: 'none'}}
                multiple={multi}
                placeholder={"Search"}>
                {optionsWithBlank.map(([label, val], idx) => <option key={idx} value={val}>{label}</option>)}
            </select>
            {
                errors.length > 0 ? (errors.map((error, idx) => (
                    <span key={idx} className="help-block">{error}</span>
                ))) : (
                    <span className="help-block">{required ? 'Required' : 'Optional'}</span>
                )
            }
        </div>
    </div>;
}