import React, {useEffect, useState} from "react";
import {NavPill} from "./NavPill";

export const NavPills = (props) => {
    const {children, activeIdx = 0} = props;
    const [activePill, setActivePill] = useState(activeIdx);

    const renderChildren = children.filter((child) => child.type.displayName === 'NavPill');

    const onPillClick = (idx) => {
        return () => {
            setActivePill(idx);
        }
    }

    return (
        <React.Fragment>
            <ul className="nav nav-pills">
                {
                    renderChildren.map(((child, idx) => <NavPill isActive={idx === activePill}
                                                                 title={child.props.title}
                                                                 key={`pill-${idx}`}
                                                                 onPillClick={onPillClick(idx)}
                            />
                        )
                    )
                }
            </ul>
            <div style={{paddingTop: '20px'}}/>
            {renderChildren[activePill].props.children}
        </React.Fragment>
    )
}