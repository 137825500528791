import React, {useRef, useEffect, Fragment} from 'react';

export const TimeAndZone = (props) => {
    const zoneRef = useRef(null);
    const zoneDropdownRef = useRef(null);
    const {
        timeLabel,
        timeName,
        timeValue,
        onTimeChange,
        zoneLabel,
        zoneName,
        zoneValue,
        timezones,
        onZoneChange
    } = props;

    const timezonesWithBlank = [["", ""], ...timezones];

    useEffect(() => {
        if (zoneRef !== null && zoneRef.current !== null) {
            const jq = window.$;
            if (zoneDropdownRef == null || zoneDropdownRef.current == null) {
                const select = jq(zoneRef.current).find('[data-enumeration]');
                zoneDropdownRef.current = jq(select[0]);
                zoneDropdownRef.current.filteringSelect($(this).data('options'));
            }
        }
    }, [zoneRef]);


    useEffect(() => {
        if (zoneDropdownRef !== null && zoneDropdownRef.current !== null) {
            zoneDropdownRef.current.off('change');
            zoneDropdownRef.current.change((e) => {
                onZoneChange(zoneName, e.target.value);
            });
        }
    }, [zoneDropdownRef, timeValue, zoneValue, onZoneChange]);

    return <Fragment>
        <div className={"form-group control-group"}>
            <label className={"col-sm-2 control-label"}>{timeLabel}</label>
            <div className={"col-sm-2 controls"}>
                <input className={"form-control"} name={timeName} style={{width: '100%'}} value={timeValue || ""}
                       onChange={(e) => {
                           onTimeChange(timeName, e.target.value)
                       }}/>
                <span className="help-block">Optional</span>
            </div>
            <div className={"col-sm-6 controls"} ref={zoneRef}>
                <select name={zoneName} defaultValue={zoneValue} data-enumeration={true} style={{display: 'none'}}
                        placeholder={zoneLabel}>
                    {timezonesWithBlank.map(([label, value], idx) => <option key={idx} value={value}>{label}</option>)}
                </select>
            </div>
        </div>
        <div className={"col-sm-12"}>
            <div className={"alert alert-warning"}>
                Valid time format: <strong>HH:MM</strong> or <strong>**:MM</strong>
            </div>
        </div>
    </Fragment>
};