$(function () {
    $("a.bulk-link").on("click", function (e) {
        e.preventDefault();
        let message = "Are you sure?";
        const action = this.dataset.action;
        if (action === "bulk_unarchive") {
            message = "Are you sure you want to unarchive the selected items?";
        } else if (action === "export_to_csv") {
            message = "Are you sure you want to export the selected items to csv?";
        }
        let confirmation = confirm(message);
        if (confirmation === false) {
            e.stopPropagation();
        }
    });
});