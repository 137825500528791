import React, {useState} from "react";
import {isObject} from "./TreeOutput";

const marginIncrement = 10;

const isPrimitive = (value) => {
    return value !== Object(value);
}

const SimpleValue = (props) => {
    const {value} = props;
    switch (value) {
        case true:
            return "true";
        case false:
            return "false";
        case null:
            return "null";
        default:
            return value;
    }
}

const getTitleCandidate = (obj) => {
    if (obj === undefined) {
        return null;
    }
    return Object.keys(obj).find((k) => {
        return typeof obj[k] == 'string';
    })
}

export const DirectoryItem = (props) => {
    const [isOpened, setIsOpened] = useState(false);
    const {content, title, subtitle, marginLeft = 0} = props;
    const icon = isOpened ? 'icon-folder-open' : 'icon-folder-close';
    let output = null;

    if (isObject(content)) {
        let objectOutput = null;
        if (Array.isArray(content)) {
            objectOutput = <div style={{marginLeft: `${marginLeft + marginIncrement}px`}}>
                <DirectoryOutput items={content}
                                 titleKey={isPrimitive(content[0]) ? null : getTitleCandidate(content[0])}/>
            </div>;
        } else {
            objectOutput = Object.keys(content).map((k, idx) => {
                return <DirectoryItem content={content[k]} title={k} marginLeft={marginLeft + marginIncrement}
                                      key={idx}/>;
            });
        }
        output = <React.Fragment>
            <p><i className={icon} style={{cursor: 'pointer'}}
                  onClick={(e) => {
                      e.stopPropagation();
                      setIsOpened(!isOpened)
                  }}/>&nbsp;{title}{subtitle != null ?
                <span style={{fontStyle: "italic", color: 'grey'}}>&nbsp;{subtitle}</span> : null}</p>
            {isOpened ? objectOutput : null}
        </React.Fragment>;
    } else {
        output = <p><i className={'icon-flag'}/>&nbsp;<strong>{title}:</strong>&nbsp;<SimpleValue value={content}/></p>;
    }
    return <div style={{marginLeft: `${marginLeft}px`}}>
        {output}
    </div>
}

export const DirectoryOutput = (props) => {
    const {items, titleKey, subtitleKey} = props;
    return (items.map((item, idx) => {
        const subtitle = subtitleKey != null ? item[subtitleKey] : null;
        return <DirectoryItem content={item} title={item[titleKey] || idx} subtitle={subtitle} key={idx}/>;
    }));
}