import React from "react"
import {Button} from "./Button";
import {CsrfInput} from "./CsrfInput";
import {PrettyArgs} from "./PrettyArgs";

export const RetryJob = (props) => {
    const {job_id, status_id, job_args} = props;
    const action = `/admin/ArchiveJobStatus/${status_id}/retry_job`;

    return <div className={"row"}>
        <div className={"col-xs-12"}>
            <form className={"form-horizontal denser"} style={{marginTop: 0}} action={action} method={"POST"}
                  noValidate={true}>
                <CsrfInput/>
                <input type={"hidden"} name={"prev_job_id"} value={job_id}/>
                <h4>You are about to retry job <strong>{job_id}</strong> with following arguments:</h4>
                <PrettyArgs job_args={job_args}/>
                <div className={"form-actions"}>
                    <div className={"btn-toolbar"}>
                        <div className={"btn-group"}>
                            <Button
                                title={"Retry"}
                                type={"submit"}
                                className={"btn-primary"}
                                icon={"icon-repeat"}
                            />
                        </div>
                        <div className={"btn-group"}>
                            <Button
                                title={"Cancel"}
                                name={"_cancel"}
                                type={"submit"}
                                icon={"icon-remove"}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>;
}