import React, {useState, useEffect} from "react";
import {Select} from "./Select";
import {Button} from "./Button";
import {CsrfInput} from "./CsrfInput";
import {ConditionInput} from "./ConditionInput";
import {Checkbox} from "./Checkbox";
import {AdminParams} from "./AdminParams";

export const BulkUnarchiveWizard = (props) => {
    let {object_types, operators, errors, initial_data, admin_params_defaults, user_role} = props;

    const initialAdminParams = Object.keys(initial_data).reduce((memo, key) => {
        if (key.startsWith("admin_")) {
            memo[key] = initial_data[key];
        }
        return memo;
    }, {});
    const [formValues, setFormValues] = useState({
        'data_export': 0,
        'conditions[]': initial_data['conditions[]'] || [["", "", ""]],
        "object_types[]": initial_data['object_types[]'] || [],
        'dry_run': initial_data['dry_run'],
        'bulk_unarchive': initial_data['bulk_unarchive'],
        ...initialAdminParams
    });

    if (errors == null) {
        errors = {};
    }

    const [columns, setColumns] = useState([]);

    const changeFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const onConditionChange = (idx, condition) => {
        const conditions = [...formValues["conditions[]"]];
        conditions[idx] = condition;
        changeFormValue('conditions[]', conditions);
    };

    const onConditionRemove = (idx) => {
        let conditions = [...formValues["conditions[]"]];
        conditions.splice(idx, 1);
        if (conditions.length === 0) {
            conditions = [["", "", ""]];
        }
        changeFormValue('conditions[]', conditions);
    };

    const onConditionAdd = (idx) => {
        const conditions = [...formValues["conditions[]"]];
        conditions.push(["", "", ""]);
        changeFormValue('conditions[]', conditions);
    };

    const exportToCsv = (e) => {
        e.preventDefault();
        setFormValues({
            ...formValues,
            ['data_export']: 1
        });
    };

    const onFormSubmit = (e) => {
        if (e != null) {
            e.preventDefault();
            e.stopPropagation();
        }
        let message = "";
        if (formValues['data_export'] === 0) {
            message = "Are you sure you want to perform unarchive with selected conditions?";
        } else {
            message = "Are you sure you want to perform csv export with selected conditions?";
        }
        let confirmation = confirm(message);
        if (confirmation === true) {
            document.getElementById('bulk_unarchive_form').submit();
        }
    }

    useEffect(() => {
        if (formValues['data_export'] === 1) {
            onFormSubmit();
            setFormValues({
                ...formValues,
                ['data_export']: 0
            });
        }
    }, [formValues['data_export']])

    useEffect(() => {
        const newValue = formValues['object_types[]'] || [];
        if (newValue.length > 0) {
            let queryString = newValue.map(v => {
                return 'sf_objects[]=' + v;
            }).join('&');
            const path = '/object_metadata/bulk_columns_rich.json?' + queryString;
            fetch(path).then(response => {
                if (response.ok) {
                    response.json().then(columns => {
                        setColumns(columns);
                    })
                }
            })
        } else {
            setColumns([]);
        }
    }, [formValues['object_types[]']]);

    useEffect(() => {
        let conditions = [...formValues['conditions[]']].filter(c => {
            return columns.includes(c[0]);
        });
        if (conditions.length === 0) {
            conditions = [["", "", ""]];
        }
        setFormValues({
            ...formValues,
            ['conditions[]']: conditions
        });
    }, [columns]);

    const method = 'post';
    const action = `/admin/condition_unarchive`;

    let adminParams = [];
    if (user_role === "admin") {
        adminParams = [
            "admin_export_row_limit",
            "admin_unarchive_row_limit"
        ];
    }

    return <div className={"row"}>
        <div className={"col-xs-12"}>
            <form className={"form-horizontal denser"}
                  id="bulk_unarchive_form"
                  action={action} method={method}
                  onSubmit={onFormSubmit}
                  noValidate={true}>
                <input type="hidden" name="data_export" value={formValues["data_export"]}/>
                <CsrfInput/>
                <Select
                    required={true}
                    name={"object_types[]"}
                    label={"Objects"}
                    options={object_types}
                    multi={true}
                    value={formValues["object_types[]"]}
                    onChange={changeFormValue}
                    errors={errors["object_types[]"]}/>
                <ConditionInput
                    required={true}
                    name={"conditions[]"}
                    label={"Condition"}
                    conditions={formValues["conditions[]"]}
                    operators={operators}
                    columns={columns}
                    onChange={onConditionChange}
                    onRemove={onConditionRemove}
                    onAdd={onConditionAdd}
                    errors={errors["conditions[]"]}
                />
                <Checkbox
                    name={"dry_run"}
                    label={"Dry run"}
                    value={formValues["dry_run"]}
                    onChange={changeFormValue}
                />
                {/*<Checkbox
                    name={"bulk_unarchive"}
                    label={"Bulk Unarchive"}
                    value={formValues["bulk_unarchive"]}
                    onChange={changeFormValue}
                />*/}
                <AdminParams adminParams={adminParams} onChange={changeFormValue}
                             formValues={formValues} placeholders={admin_params_defaults}/>
                <div className={"form-group form-actions"}>
                    <div className={"col-sm-offset-2 col-sm-10"}>
                        <div className="btn-toolbar" role="toolbar">
                            <div className="btn-group" role="group">
                                <Button
                                    title={'Unarchive'}
                                    type={"submit"}
                                    className={"btn-primary"}
                                    icon={"icon-white icon-ok"}
                                />
                            </div>
                            <div className="btn-group" role="group">
                                <Button
                                    title={'Export to csv'}
                                    onClick={exportToCsv}
                                    className={"btn-warning"}
                                    icon={"icon-white icon-folder-open"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
}